<template>
  <!-- <img src="../assets/background2.jpg" alt="" class="bg"> -->
  <div class="container-fluid">
    <div id="particles-js"></div>
      <div class="row ">
        <Pubblicity />
        <div class="col-md-4 ml-sm-0 mr-sm-0 d-none d-md-block">          
        </div>
        <div class="col-md-8 col-sm-12 d-flex flex-column justify-content-center align-items-end">
          <LeftHome/>
        </div>
      </div>
      <footer class="bg-dark footer text-center text-lg-start">
      <!-- Copyright -->
      <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
          © Copyright 
          {{ anno }}
           CYBETOWER® | Servizio offerto da 
          <a class="green" target="_blank" href="https://cybetower.swiss">cybetower.swiss</a>
          | CYBETOWER® è un marchio registrato | All Rights Reserved | 
          <a class="green" target="_blank" href="https://cybetower.swiss/privacy_policy/" >Privacy policy</a>
        </div>
        <!-- Copyright -->
    </footer>
  </div>
  
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent } from 'vue';
import '@/scripts/particles.js'


export default {
  name: 'HomeView',
  components: {
      LeftHome: defineAsyncComponent(() => import('@/pages/LeftHome.vue')),
      Pubblicity: defineAsyncComponent(()=> import('@/components/PubblicityComponent.vue'))

  },
  data() {
    return {
      anno: null,
    }
  },
  methods: {
    getYear(){
       let a = new Date;
       this.anno = a.getFullYear()
    }
  },
  mounted() {
    this.getYear(),
    
particlesJS('particles-js',

{
  "particles": {
    "number": {
      "value": 80,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#ffffff"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.6,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 4,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 40,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 2,
      "direction": "none",
      "random": false,
      "straight": false,
      "out_mode": "out",
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "grab"
      },
      "onclick": {
        "enable": true,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 200,
        "line_linked": {
          "opacity": 0.5
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true,
  "config_demo": {
    "hide_card": false,
    "background_color": "#b61924",
    "background_image": "",
    "background_position": "50% 50%",
    "background_repeat": "no-repeat",
    "background_size": "cover"
  }
}

);

  },
}
</script>

<style>
:root {
	--d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgb(255, 100, 100);
	--c2: rgba(255, 168, 168, 0.2);
}
  body {
    background: black;
    height: 100vh;
    z-index: -2;
    letter-spacing: 1px;
    width: 100vw;
    scroll-behavior: smooth;
    display: grid;
    grid-template-rows: 1fr auto;
    overflow-x: hidden;
    background-color: black !important;
    background-image: url('../assets/background2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  body .container-fluid {
    padding: 0;
  }
  .grecaptcha-badge {
    z-index: 1;
  }
  @media only screen and (max-width: 730px) {
    body {
      display: flex;
      flex-direction: column;
    }
    .consigli-landing img{
      margin:0;
    }
    .consigli-landing img.arrows{
      margin:0 auto;
    }
    ul.cross, ul.check {
      margin: 0;
      padding: 0;
      width: 100%;
    }
    ul.cross li, ul.check li {
      width: 100%;
    }
    .pp-bg h4.text-left {
      padding: 20px 0px;
    }
    input#submit {
      position: relative;
      transform: translateX(-50%);
      left: 50%;
    }
    div.mb-4.needs-validation.form {
      width: 100%;
    }
  }
  img.bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -2;
    /* transform: scale(-1,1); */
  }

  .row, .container-fluid, #app {
    min-height: 95vh;
  }
  .footer {
    position:relative;
    min-height: 5vh;
    color: white;
    text-align: center;
    font-size: 14px;
    z-index: 1!important;
  }

  a.green {
    text-decoration: none;
    color: #66ff00;
  }
  a.green:hover {
    color: #ffd700;
  }
  #particles-js {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

</style>

